<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <!-- <div class="area_left">
          <strong class="tit_tbl">공지사항 상세</strong>
        </div> -->
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="viewModel.model.status | convertIdToText('blog_status')"
                :badgeStyle="viewModel.model.status | convertIdToColor('blog_status')"
                :badgeFill="false" />
            </span>
          </td>
          <th>타겟구분</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="viewModel.model.target | convertIdToText('user_target')"
                :badgeStyle="viewModel.model.target | convertIdToColor('user_target')"
                :badgeFill="false" />
            </span>
          </td>
        </tr>
        <tr>
          <th>등록일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.createdAt | convertDateFormat() }}</span>
          </td>
          <th>최종수정일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.updatedAt | convertDateFormat() }}</span>
          </td>
        </tr>
        <tr>
          <th>제목</th>
          <td colspan="3">
            <span class="txt_tbl">{{ viewModel.model.title }}</span>
          </td>
        </tr>
        <tr>
          <th>내용</th>
          <td colspan="3">
            <div class="editor_view" v-html="viewModel.model.contents"></div>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="LANDING_BLOG_NEWS_LIST"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
        <Button
          btnSize="medium"
          btnStyle="secondary"
          text="미리보기"
          @onClickBtn="viewModel.onClickNewsPreviewPopup()"/>
      </div>
      <div class="area_right">
        <!-- <Button
          btnSize="medium"
          btnStyle="fourthly"
          text="삭제"
          @onClickBtn="viewModel.onClickDelete()"/> -->
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="수정"
          routeName="LANDING_BLOG_NEWS_MODIFY"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="복제"
          routeName="LANDING_BLOG_NEWS_REGISTER"
          :routeQuery="{ copyId : viewModel.model.id }"/>
      </div>
    </Sticky>
    <template v-slot:popup>
      <NewsPreviewPopup
        v-if="viewModel.isNewsPreviewPopup"
        :detailData="viewModel.model"
        @onClickClose="viewModel.onClickCloseNewsPreviewPopup()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// popup
import NewsPreviewPopup from '@/views/landing/blog/news/view/popup/NewsPreviewPopup'
// viewModel
import NewsDetailViewModel from '@/views/landing/blog/news/viewModel/NewsDetailViewModel'
import { convertIdToText } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils';

export default {
  name:'NewsDetail',
  components:{
    PageWithLayout,
    TableView,
    Badge,
    Sticky,
    Button,

    NewsPreviewPopup,
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);
  },
  data(){
    return{
      viewModel: new NewsDetailViewModel(),
    }
  },
}
</script>
<style scoped>
.img_g{max-width:400px}
</style>