import NewsModel from '@/views/landing/blog/news/model/NewsModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import { groupEncryptWithRSA } from '@lemontree-ai/lemontree-admin-common-front/utils/RsaUtils';
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class NewsDetailViewModel {
  constructor() {
    this.model = {};
    this.isModify = false;
    this.registerValid = {
      title:{
        value: 'title',
        valueType:'',
        isValid:false,
      },
      contents:{
        value: 'contents',
        valueType:'',
        isValid:false,
      },
    },
    this.isNewsPreviewPopup = false;
  }

  init(id){
    this.model = new NewsModel();
    if(id){
      this.isModify = true;
      this.getNewsDetail(id);
    }
  }
  initCopyRegister(copyId) {
    this.model = new NewsModel();
    this.getNewsDetail(copyId);
  }
  onClickNewsPreviewPopup(){
    this.isNewsPreviewPopup = true;
  }
  onClickCloseNewsPreviewPopup(){
    this.isNewsPreviewPopup = false;
  }

  registerValidatedResult(){
    return pageValidated(this.registerValid, this.model)
  }

  onClickDelete(){
    const requestDelete = () => {
      this.deleteNewsDetail();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `공지사항을 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  onComplete(){
    if(this.isModify){
      this.putNewsDetail();
    }else{
      this.postNewsDetail();
    }
  }
  // 상세
  getNewsDetail(id){
    const path = `${apiPath.NEWS_DETAIL.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 등록
  postNewsDetail(){
    const path = `${apiPath.NEWS_REGISTER}`;
    const data = this.model.getRegisterData();
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '공지사항이 등록되었습니다');
      router.push({ name: 'LANDING_BLOG_NEWS_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 수정
  putNewsDetail(){
    const path = `${apiPath.NEWS_MODIFY.format(this.model.id)}`;
    const data = this.model.getModifyData();
    // console.log(data);
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '공지사항이 수정되었습니다');
      router.push({ name: 'LANDING_BLOG_NEWS_DETAIL' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 삭제
  deleteNewsDetail(){
    const path = `${apiPath.NEWS_DELETE.format(this.model.id)}`;
    DELETE_REQUEST(path).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', '공지사항이 삭제되었습니다');
      router.push({ name: 'LANDING_BLOG_NEWS_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}